/**
 * NOTE: Feedbacker is added by gatsby-browser,
 * so we don't need to add the actual container component here
 */
import React from 'react';
import { feedback } from 'react-feedbacker';

import { Button, DevtoolsWrapper } from '@/components';

const example = () => {
  return (
    <DevtoolsWrapper>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        <Button
          type="button"
          variant="primary"
          onClick={() => feedback.success('Success message')}
        >
          Add success
        </Button>

        <Button
          type="button"
          variant="primary"
          onClick={() => feedback.warning('Warning message')}
        >
          Add warning
        </Button>

        <Button
          type="button"
          variant="primary"
          onClick={() => feedback.error('Error message')}
        >
          Add error
        </Button>

        <Button
          type="button"
          variant="primary"
          onClick={() => feedback.info('Info message')}
        >
          Add info
        </Button>
      </div>
    </DevtoolsWrapper>
  );
};

export default example;
